<template>
  <DashboardTable
    class="discounts__table"
    :align-right-after="3"
    :columns="[
      { title: $t('discount.code') },
      { title: $t('general.startDate'), class: 'discounts__starts' },
      { title: $t('general.expires'), class: 'discounts__expires' },
      { title: $t('general.quantity') },
      { title: $t('general.options') },
    ]"
    show-search
    state-key="discounts"
  >
    <TableRow
      v-for="discount in discounts"
      :id="discount.id"
      :key="discount.id"
      :class="{ 'discounts--expired': discount.is_expired }"
      route-name="discounts.edit"
    >
      <td>
        <ChecDataPill
          :color="discount.is_expired ? 'gray' : 'green'"
          :class="{ 'discounts__code--expired': discount.is_expired }"
        >
          {{ discount.code }}
        </ChecDataPill>
        <span class="discounts__code-description">
          {{ $t('discount.discountOffProduct', {
            discount: discount.type === 'percentage'
              ? `${discount.value}%`
              : `${currencySymbol}${discount.value}`,
            product: discountFor(discount),
          }) }}
        </span>
      </td>
      <td class="discounts__starts">
        <Timestamp
          v-if="discount.starts_on"
          :timestamp="discount.starts_on"
          hide-time
        />
        <span v-else>-</span>
      </td>
      <td class="discounts__expires">
        <Timestamp
          v-if="discount.expires_on"
          :class="{ 'discounts__code--expired': discount.is_expired }"
          :timestamp="discount.expires_on"
          hide-time
        />
        <span v-else>{{ $t('discount.neverExpires') }}</span>
        <span v-if="discount.is_expired" class="discounts__code-expired">
          {{ $t('general.expired') }}
        </span>
      </td>
      <td>{{ discount.limit_quantity ? discount.quantity : '&infin;' }}</td>
      <td>
        <ChecOptionsMenu class="inline-block">
          <ChecOption @option-selected="() => handleEdit(discount.id)">
            {{ $t('general.edit') }}
          </ChecOption>
          <ChecOption destructive @option-selected="() => handleDelete(discount.id)">
            {{ $t('general.delete') }}
          </ChecOption>
        </ChecOptionsMenu>
      </td>
    </TableRow>
  </DashboardTable>
</template>

<script>
import {
  ChecOption,
  ChecDataPill,
  ChecOptionsMenu,
} from '@chec/ui-library';
import Timestamp from '@/components/Timestamp.vue';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'DiscountsTable',
  components: {
    TableRow,
    DashboardTable,
    Timestamp,
    ChecOption,
    ChecDataPill,
    ChecOptionsMenu,
  },
  props: {
    discounts: {
      type: Array,
      default: () => [],
    },
    currencySymbol: {
      type: String,
      default: '$',
    },
  },
  methods: {
    /**
     * Delete a discount by its ID
     *
     * @param {string} discountId
     */
    handleDelete(discountId) {
      this.$emit('delete-discount', discountId);
    },
    /**
     * Navigate to the 'edit' view for a discount
     *
     * @param {string} discountId
     */
    handleEdit(discountId) {
      this.$router.push({
        name: 'discounts.edit',
        params: { id: discountId },
      });
    },
    /**
     * Displays "1 product", "3 products", or "All" for discounts depending
     * on which products they apply to.
     *
     * @param {object} discount
     * @returns {string}
     */
    discountFor(discount) {
      if (discount.product_ids.length === 0) {
        return this.$t('discount.all');
      }
      return this.$tc('product.count', discount.product_ids.length);
    },
  },
};
</script>

<style lang="scss">
.discounts {
  &__code {
    &--expired {
      @apply uppercase line-through;
    }
  }

  &__code-description {
    @apply block mt-2;
  }

  &__code-expired {
    @apply block;
  }

  &__table {
    @apply table-fixed w-full;

    th,
    td {
      &:nth-child(n+4) {
        @apply text-right;
      }
    }
  }

  &__starts {
    @apply text-center;
  }

  &__expires {
    @apply text-center caps-xxs;
  }
}
</style>
