<template>
  <div class="discounts-home">
    <DashboardHeader :title="$t('discount.discounts')">
      <ChecButton variant="round" color="primary" @click="handleAdd">
        <template #icon>
          <ChecIcon icon="plus" />
        </template>
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>

    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('discount.introSplash.title')"
      button
      @click="handleAdd"
    >
      <i18n path="discount.introSplash.description" tag="span">
        <template #more>
          <a href="http://support.commercejs.com/en/articles/513213-discount-codes" target="_blank">
            {{ $t('discount.introSplash.more') }}
          </a>
        </template>
      </i18n>
    </IntroSplash>
    <DiscountsTable
      v-else
      :discounts="discounts"
      :currency-symbol="currencySymbol"
      @delete-discount="handleDelete"
    />
    <DashboardPagination state-key="discounts" />

    <router-view
      :merchant-currency="currencySymbol"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  ChecButton,
  ChecIcon,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardPagination from '@/components/Pagination.vue';
import IntroSplash from '@/components/IntroSplash.vue';
import confirm from '@/mixins/confirm';
import crud from '@/mixins/crud';
import addNotification from '@/mixins/addNotification';
import DiscountsTable from '../components/DiscountsTable.vue';

export default {
  name: 'DiscountsHome',
  components: {
    ChecButton,
    ChecIcon,
    DashboardHeader,
    DashboardPagination,
    IntroSplash,
    DiscountsTable,
  },
  mixins: [
    crud('discounts', true),
    addNotification,
    confirm,
  ],
  computed: {
    ...mapState('merchant', ['merchant']),
    /**
     * Returns the merchant's currency symbol, e.g. $ or €
     *
     * @returns {string}
     */
    currencySymbol() {
      if (this.merchant && this.merchant.currency) {
        return this.merchant.currency.symbol;
      }
      return '$';
    },
    /**
     * Show intro card section when there are no discounts and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.discounts.length === 0 && !this.isLoading;
    },
  },
  methods: {
    /**
     * Navigate to the "add discounts" view
     */
    handleAdd() {
      this.$router.push({ name: 'discounts.add' });
    },
    /**
     * Confirms, then deletes the provided discount by its ID
     *
     * @param {string} discountId
     */
    async handleDelete(discountId) {
      if (await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('discount.confirmDelete'),
      )) {
        this.delete(discountId)
          .then(() => {
            this.addNotification(this.$t('discount.deleted'));
          })
          .catch((error) => {
            this.addNotification(error, 'error');
          });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
